<template>
  <div id="bannerTournament" v-if="banners.length > 0">
    <b-button class="btn-hide-carousel" @click="hideCarousel()">
      <i v-if="hide" class="fa fa-arrow-circle-o-down"></i>
      <i v-else class="fa fa-arrow-circle-o-up"></i>
    </b-button>
    <b-carousel fade v-show="hide">
      <b-carousel-slide v-for="(banner, ind) in banners" :key="ind" :caption="banner.name">
        <a :href="banner.link" target="_blank" slot="img">
          <img :src="banner.image" alt="Demo" class="img-fluid">
        </a>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    banners: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      hide: true
    }
  },
  methods: {
    hideCarousel () {
      this.hide = !this.hide
    }
  }
}
</script>
