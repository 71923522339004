var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"w-100 bg-primary menubar"},[_c('ul',[_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryTournament === 'home' },attrs:{"to":{
					name: 'TournamentHome',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('home')))])],1),_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryTournament === 'bests' },attrs:{"to":{
					name: 'TournamentBests',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: this.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('theBest')))])],1),_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryTournament === 'ranking' },attrs:{"to":{
					name: 'TournamentRankinglayers',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v("Ranking")])],1),_c('li',[_c('router-link',{class:{
					'router-link-active': _vm.menuPrimaryTournament === 'signings'
				},attrs:{"to":{
					name: 'TournamentSignings',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('signings')))])],1),_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryTournament === 'rules' },attrs:{"to":{
					name: 'TournamentRules',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('rules')))])],1),_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryTournament === 'banned' },attrs:{"to":{
					name: 'TournamentBanned',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('banned')))])],1)]),(_vm.menuPrimaryTournament === 'home')?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentHome',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('divisions')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentTeams',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('teams')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentTransfers',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('transfers')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentMovements',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('movements')))])],1)]):_vm._e(),(_vm.menuPrimaryTournament === 'bests')?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentBests',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('theBest')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentBestGks',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v("Gk")])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentBestAssists',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('assistances')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentBestScorers',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.$tc('scorer', 2))))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentBestMvps',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v("Mvp")])],1)]):_vm._e(),(_vm.menuPrimaryTournament === 'ranking')?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentRankinglayers',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('players')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'TournamentRankingTeams',
					params: { lang: _vm.lang, console: _vm.console, tournamentSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('teams')))])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }