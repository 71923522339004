<template>
  <div class="w-100">
    <div class="container-fluid">
      <div class="row header-section" v-if="tournamentFront.tournament">
      <img v-if="tournamentFront.bg" slot="img" class="header-section-bg" :src="tournamentFront.bg.image" :srcset="`${tournamentFront.bg.image_sm} 600w, ${tournamentFront.bg.image_md} 1024w, ${tournamentFront.bg.image} 2000w`">
      <div class="col-3 col-sm-3 col-md-2 col-lg-1">
        <img :src="tournamentFront.tournament.image" :alt="tournamentFront.tournament.name" class="img-fluid">
      </div>
      <div class="col-9 col-sm-9 col-md-10 col-lg-11">
        <h1 class="title">{{tournamentFront.tournament.name}}</h1>
      </div>
      <div class="col-12">
        <p>{{tournamentFront.tournament.description}}</p>
        <b-button variant="primary" size="sm" class="mr-2" v-for="(button, i) in tournamentFront.buttons" :key="`but-${i}`" :href="button.link" :target="button.showin">{{ button.label }}</b-button>
      </div>
      <div class="btns-floats">
        <div class="mb-2">
          <b-button :to="{ name: 'Champions', params: { lang: lang, console: console, tournamentSlug: $route.params.tournamentSlug } }" variant="outline-primary" class="btn-icon">
            <img src="../../../assets/images/champions.png" alt="Campeones">
          </b-button>
          <b-button :to="{ name: 'Donations', params: { lang: lang } }" variant="outline-primary" class="btn-icon">
            <img src="../../../assets/images/donate.png" alt="Donaciones">
          </b-button>
          <b-button target="_blank" :href="`https://www.facebook.com/${this.tournamentFront.tournament.facebook}`" variant="outline-primary" class="btn-icon">
            <i class="fa fa-facebook"></i>
          </b-button>
        </div>
        <b-button :disabled="!isAuthenticated" variant="outline-primary" @click="addToFavourites()">
          <span class="mr-2">{{tournamentFront.tournament.favourites}}</span>
          <i class="fa fa-star t-yellow"></i>
        </b-button>
      </div>
    </div>
    </div>
    <menu-tournament />
    <!-- Carrusel de usuarios en linea -->
    <div class="col-12">
      <router-view />
    </div>
    <carousel-users-online :show-name="false" />
    <banner-carrusel :banners="banners" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MenuTournament from  '../partials/MenuTournament'
import CarouselUsersOnline from '../../partials/CarouselUsersOnline'
import BannerCarrusel from '../partials/Banners'

export default {
  components: {
    MenuTournament,
    CarouselUsersOnline,
    BannerCarrusel
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'tournamentFront',
      'isAuthenticated'
    ])
  },
  data () {
    return {
      banners: []
    }
  },
  mounted () {
    if (!this.tournamentFront.tournament) {
      this.fetchData()
    }
  },
  methods: {
    addToFavourites () {
      const slug = this.$route.params.tournamentSlug
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const path = `auth/${lang}/console/${console}/tournament/${slug}`
      this.$axios.post(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    fetchData () {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.tournamentSlug
      const path = `${lang}/console/${console}/tournament/${slug}`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.banners = data.banners
        this.$store.dispatch('SET_TOURNAMENT_FRONT', data)
      })
    }
  }
}
</script>
