<template>
	<nav class="w-100 bg-primary menubar">
		<ul>
			<li>
				<router-link
					:to="{
						name: 'TournamentHome',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryTournament === 'home' }"
					>{{ $t('home') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentBests',
						params: { lang: lang, console: console, tournamentSlug: this.slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryTournament === 'bests' }"
					>{{ $t('theBest') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentRankinglayers',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryTournament === 'ranking' }"
					>Ranking</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentSignings',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					:class="{
						'router-link-active': menuPrimaryTournament === 'signings'
					}"
					>{{ $t('signings') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentRules',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryTournament === 'rules' }"
					>{{ $t('rules') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentBanned',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryTournament === 'banned' }"
					>{{ $t('banned') }}</router-link
				>
			</li>
		</ul>
		<ul v-if="menuPrimaryTournament === 'home'">
			<li>
				<router-link
					:to="{
						name: 'TournamentHome',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('divisions') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentTeams',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('teams') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentTransfers',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('transfers') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentMovements',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('movements') }}</router-link
				>
			</li>
		</ul>
		<ul v-if="menuPrimaryTournament === 'bests'">
			<li>
				<router-link
					:to="{
						name: 'TournamentBests',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('theBest') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentBestGks',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>Gk</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentBestAssists',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('assistances') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentBestScorers',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $tc('scorer', 2) | toCapitalize }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentBestMvps',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>Mvp</router-link
				>
			</li>
		</ul>
		<ul v-if="menuPrimaryTournament === 'ranking'">
			<li>
				<router-link
					:to="{
						name: 'TournamentRankinglayers',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('players') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'TournamentRankingTeams',
						params: { lang: lang, console: console, tournamentSlug: slug }
					}"
					exact
					>{{ $t('teams') }}</router-link
				>
			</li>
		</ul>
	</nav>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				slug: ''
			}
		},
		computed: {
			...mapGetters(['lang', 'console', 'menuPrimaryTournament'])
		},
		mounted() {
			this.slug = this.$route.params.tournamentSlug
		}
	}
</script>
